import React from "react";
import { navigate } from '@reach/router'
import { isLoggedIn } from "../../services/auth";

export default class Merchantcard extends React.Component {

    async redirectPage() {

        navigate(`/product/netsclick/application`)
        if (typeof window !== "undefined") {
            window.location.reload()
        }

    }

    render() {
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;
        let intMethod = null
        if (application) {
            intMethod = application.int_method
            intMethod = intMethod.toUpperCase();
        }


        return (
            <div >
                <div><b>Company Name : {application ? application.merchant_name : ''}</b></div>
                <div><b>Integration Mode : {intMethod ? intMethod : null}</b></div>
                <div className='hyperlink' onClick={() => this.redirectPage()}><u>Switch to other merchant / new merchant </u></div>
            </div>
        )

    }

}
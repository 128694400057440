export const MAX_INPUT_LABEL_LENGTH = 64;

export const APP_STATUS = {
    'P': 'Pending Review',
    'A': 'Approved',
    'R': 'Rejected'
}
export const NETSCLICK_APP_STATUS = {
    'P': 'In Progress',
    'A': 'Completed',
    'R': 'Rejected'
}

export const COLOR_CONSTANT = {
    'P': 'warning',
    'A': 'success',
    'R': 'danger'
}
export const REFUND_APP_STATUS = {
    'N': 'Not enabled.',
    'A': 'Enabled'
}
export const MTLS_APP_STATUS = {
    'N': 'Not enabled.',
    'P': 'Enabling in progress',
    'A': 'Enabled'
}

export const APPLICATION_KEY = 'application'
export const SIGNERS_KEY = 'signers'

export const NETSCLICK_PAGE_OVERVIEW = 'overview'

export const ENV_UAT = 'UAT'
export const ENV_PROD = 'PROD'

export const MENU_ITEM_SIGNER_CERT = 'signerCertMenu'
export const MENU_ITEM_SDK_LIST = 'sdkDetailsMenu'
export const MENU_ITEM_TEMP_INT_SETTINGS = 'tempIntSettingsMenu'
export const MENU_ITEM_INTEGRATION_HOME = 'integrationApp'

export const SUBMENU_INT_TEMP_SETTINGS = 'tempIntSettings'
export const SUBMENU_INT_TEMP_UAT = 'tempIntUat'
export const SUBMENU_INT_TEMP_PROD = 'tempIntProd'

export const MENU_ITEM_TEMP_INT_MID_TID = 'midtid'
export const MENU_ITEM_TEMP_INT_SFTP = 'sftp'
export const MENU_ITEM_TEMP_INT_VEKY = 'vkey'
export const MENU_ITEM_TEMP_INT_MAP = 'mapkey'
export const MENU_ITEM_TEMP_INT_REFUND = 'refund'
export const MENU_ITEM_TEMP_INT_MTLS = 'mtls'
export const MENU_ITEM_TEMP_INT_API_CREDENTIALS = 'apicredentials'

export const CONST_UNDEFINED = "undefined"
import ReCAPTCHA from "react-google-recaptcha"
import React from "react";
import errorConstants from '../../utils/strings'
import { submitSalesLead } from '../../services/api'
import { Input, FormGroup, Label, Button, Modal, ModalHeader, ModalFooter, ModalBody, Alert } from 'reactstrap'

export default class Query extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobile: null,
            message: null,
            name: null,
            openEnquiryModal: false,
            validateForm: false,
            displayError: false,
            fetched: false,
            company: null,
            captcha: null,
            openConfirmationModal: false,
            salesLead : props  ? props.salesLead : false

        }
    }
    modalViewControl(value) {
        if (!value) {
            this.setState({
                openEnquiryModal: value,
                mobile: null,
                message: null,
                displayError: false,
                validateForm: false
            })
        } else {
            this.setState({
                openEnquiryModal: value
            })
        }

    }
    validateForm() {
        console.log(this.state)
        let validateForm = this.state.captcha && this.state.mobile && this.state.name
        this.setState({
            validateForm: validateForm
        })
    }
    captchaChange = (value) => {
        const token = value

        this.setState({
            captcha: token
        }, () => {
            this.validateForm()
        })
    }
    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;

        this.setState({
            [name]: value
        }, () => {
            this.validateForm()
        });
    }
    closeModal() {
        this.setState({ openEnquiryModal: false, openConfirmationModal : false })
    }
    async handleSubmit() {
        let { name, mobile, email, message, validateForm, company , salesLead} = this.state

        if (validateForm) {
            let inputObj = {
                "name": name,
                "email": email,
                "phone": mobile,
                "company": company,
                "message": message,
                "type" : salesLead ? 1 : 2
            }

            submitSalesLead(inputObj)
            this.setState({
                openEnquiryModal: false,
                email: null,
                mobile: null,
                company: null,
                message: null,
                name: null,
                openConfirmationModal: true
            })
        }
    }

    render() {
        let { displayError, openEnquiryModal, openConfirmationModal, salesLead } = this.state

        if (openEnquiryModal) {
            return (
                <div>
                    <Modal isOpen={openEnquiryModal} toggle={() => this.modalViewControl(false)}>
                        <ModalHeader toggle={() => this.modalViewControl(false)} >Leave your contact details and our sales consultant will reach out to you to share more details NETS Click</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="name"
                                >Name</Label>
                                <Input
                                    required
                                    id="name"
                                    maxLength="64"
                                    onChange={this.handleChange}
                                    value={this.state.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="company"
                                >Company Name</Label>
                                <Input
                                    required
                                    id="company"
                                    maxLength="128"
                                    onChange={this.handleChange}
                                    value={this.state.company} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="mobilenumber"  >Contact Number</Label>
                                <Input
                                    required
                                    id="mobile"
                                    type="number"
                                    onChange={this.handleChange}
                                    value={this.state.mobile} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email"
                                >Email</Label>
                                <Input
                                    required
                                    id="email"
                                    maxLength="128"
                                    onChange={this.handleChange}
                                    value={this.state.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="description" >Message (if any)</Label>
                                <Input
                                    type="textarea"
                                    id="message"
                                    maxLength="1024"
                                    onChange={this.handleChange}
                                    value={this.state.message} />
                            </FormGroup>
                            <ReCAPTCHA
                                sitekey={process.env.CAPTCHA_SITEKEY}
                                onChange={this.captchaChange}
                                ref={(r) => this.recaptcha = r}
                            />
                        </ModalBody>
                        <div className='errorMessage'>{displayError ? errorConstants[this.state.errorMessage] : ''}</div>
                        <ModalFooter>
                            <Button color="primary"
                                disabled={!this.state.validateForm}
                                onClick={(e) => this.handleSubmit(e)}

                            >Submit</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </div>
            )
        } 
        if (openConfirmationModal) {
            return (
                <div>

                    <Modal isOpen={openConfirmationModal} >
                        <ModalBody>
                            Your Query Submitted successfully to sales lead.
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.closeModal()}>
                                OK
                            </Button>{' '}

                        </ModalFooter>
                    </Modal>
                </div>
            )
        }
        if(salesLead){
            return (
                <div className='userForm'>
                    <Alert color="primary" style={{ backgroundColor: "#F5F7FB" }}>
                         <div style={{ textAlign: 'center' }}>Not sure where to start ?</div>
                         <div><a href onClick={() => this.modalViewControl(true)}><u>Click here</u></a> to reach out to our friendly sales consultant who will guide you on the application process</div>
                     </Alert>
                 </div> 
            )
        } else {
            return (
                <div>
                    <div className='userForm'>
                        <Alert responsive color="primary" style={{ backgroundColor: "#F5F7FB" }}>
                            <div ><b>Need help ?</b></div>
                            <div><a href onClick={() => this.modalViewControl(true)}><u><b>Click here</b></u></a> to reach out to our friendly NETS Click support team who will guide you on the integration process.</div>
                        </Alert>
                    </div>
                </div>
            )
        }
        
    }

}
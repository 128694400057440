import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { navigate } from '@reach/router'
import { getIntegrationStatus } from "./../services/api"
import { ENV_UAT  } from '../utils/constants';
import { isLoggedIn } from "./../services/auth"

class LeftNavBar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectMenuItem: this.props.menu ? this.props.menu : 'overview',
      uatCredentialsStatus : null
    }

  }
 
 async componentWillMount(){
  let uatCredentialsStatus = null, data =null
  const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;
  if(application){
    data = await getIntegrationStatus({
                  email: application.email,
                  netsclickAppID: application.nc_app_id
                })

  }
  let signerStatus = data && data.length >= 1 ? data[1] : null
      if (signerStatus) {
        uatCredentialsStatus = signerStatus.find(signers => signers.ENV_PROFILE == ENV_UAT)
      }
      this.setState({
        uatCredentialsStatus : uatCredentialsStatus
      })
 }

  async redirectPage(pageName) {

    navigate(`/product/netsclick/${pageName}`)
    if (typeof window !== "undefined") {
      window.location.reload()
    }
    this.setState({
      selectMenuItem: pageName
    })
  }



  render() {
    let { selectMenuItem, uatCredentialsStatus } = this.state
    return (
      <div>
        <Nav vertical pills >
          <NavItem className='LeftSideMenu'>
            <NavLink active={selectMenuItem == 'overview' ? true : false} href="" onClick={() => this.redirectPage('overview')}>Overview</NavLink>
          </NavItem >
          <NavItem className='LeftSideMenu'>
            <NavLink active={selectMenuItem == 'materials' ? true : false} href='' onClick={() => this.redirectPage('materials')}>Step 1 <br />Material</NavLink>
          </NavItem>
          <NavItem className='LeftSideMenu'>
            <NavLink >Step 2 <br />Security Checklist<p className='font-size-small'>(Feature will be available soon)</p></NavLink>
          </NavItem>
          <NavItem className='LeftSideMenu'>
            <NavLink active={selectMenuItem == 'uatSetupCredentials' ? true : false} href='' onClick={() => this.redirectPage('uatSetupCredentials')}>Step 3 <br />UAT Setup / Credentials</NavLink>
          </NavItem>
          <NavItem className={ uatCredentialsStatus && uatCredentialsStatus.APP_TYPE == 'P' ? 'LeftSideMenu' : 'LeftSideMenu disabledBtn text-muted'}>
            <NavLink active={selectMenuItem == 'prodSetupCredentials' ? true : false} href='' onClick={() => this.redirectPage('prodSetupCredentials')}>Step 4 <br />Production Setup / Credentials</NavLink>
          </NavItem> 
        </Nav>
      </div>
    );
  }
}

export default LeftNavBar;

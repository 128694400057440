import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { isLoggedIn } from "../../../services/auth";
import { Cache } from "aws-amplify";
import Layout from '../../../components/layout';
import { Banner } from '../../../components/banner';
import LeftNavBar from '../../../containers/navbar'
import { FormGroup, Col } from 'reactstrap'
import Query from '../../../containers/netsclick/query';
import { getCommonCredentialsDetails } from '../../../services/api';
import Merchantcard from '../../../containers/netsclick/merchantcard';

export default class netsClick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            selectedApp: null,
            commonCredentialsData: null

        }
    }
    async componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }

    }
    async componentWillMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") != 1) {
            navigate(`/account/mfauth/`, { state: { netsClick: true } })
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;
        if (application) {
            let data = await getCommonCredentialsDetails(application.nc_app_id)

            this.setState({
                commonCredentialsData: data[0],
                selectedApp: application,
                fetched: true
            })
        }

    }
    redirectPage(pageId) {
        navigate(`/product/netsclick/${pageId}`)
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }

    render() {
        let { fetched, commonCredentialsData } = this.state
        if (!fetched) {
            return <div class="loading"></div>
        }
        return (
            <Layout>
                <Banner title={`NETS Click`} />
                <div className='leftAligned'>

                    <FormGroup row>
                        <Col sm={9}>
                            <h4><b>Welcome to NETS Click developer portal page.</b></h4>
                        </Col>
                        <Merchantcard />
                    </FormGroup>
                </div>
                <FormGroup row >
                    <Col sm={2}>
                        <LeftNavBar />
                    </Col>
                    <Col sm={10} >
                        {
                            commonCredentialsData ? <div>
                            <h2><b>Materials</b></h2>
                            <div className='leftAligned'>
                                <h5><b>Common credentials for development (non-MTLS API)</b></h5>
                                <h5 className='left'><b>MID & TID :</b></h5>
                                <h6><b>1. Direct Purchase</b></h6>
                                <h6>TID : {commonCredentialsData.dp_tid}</h6>
                                <h6>RID : {commonCredentialsData.dp_rid}</h6>
                                <h6><b>2. CFA</b></h6>
                                <h6>TID : {commonCredentialsData.cfa_tid}</h6>
                                <h6>RID : {commonCredentialsData.cfa_rid}</h6>
                                <h5 className='topAligned'><b>Development API Keys :</b></h5>
                                <h6><b>API Key :</b></h6>
                                <h6>{commonCredentialsData.api_key}</h6>
                                <h6><b>Secret :</b></h6>
                                <h6>{commonCredentialsData.secret_key}</h6>
                            </div>

                        </div> : ''
                        }
                    </Col>
                </FormGroup>
                <Query />

            </Layout>
        )
    }
}

import React from 'react'

export const Banner = props => {
    return(
        <div className="wp-block-cover has-background-dim-80 has-background-dim has-left-content aligncenter"
                style={{
                    backgroundImage:"url(/assets/images/header-bg2.png)",
                    backgroundColor:"rgb(12,30, 65)",
                    marginBottom: "0",
                    minHeight: "100px",
                    maxHeight: "100px"
            }}>
                <p className="wp-block-cover-text" style={{margin: `0 2rem`}}>
                    {props.title}
                </p>
        </div>
    )
}